import ServiceCompatible from "@/components/sections/ServiceCompatible";
import modalAnimationData from "@/img/lottie_tracking_anim.json";
import GlobeImage from "@/img/wavytech.png";
import Counter from "@cospex/client/components/Counter";
import FeaturesList from "@cospex/client/components/FeaturesList";
import SearchPhone from "@cospex/client/components/SearchPhone";
import useAuth from "@cospex/client/hooks/useAuth";
import { useTitle } from "@cospex/client/hooks/useTitle";
import useTranslation from "@cospex/client/hooks/useTranslation";
import HomepageGraphic from "@cospex/client/tracker/components/HomepageGraphic";
import { HomeCard, Section } from "@cospex/client/tracker/components/Layout";
import LocateCard from "@cospex/client/tracker/components/Locate";
import Pricing from "@cospex/client/tracker/components/Pricing";
import Application from "@cospex/client/tracker/components/icons/Application";
import DeviceIcon from "@cospex/client/tracker/components/icons/Device";
import Network from "@cospex/client/tracker/components/icons/Network";
import Phone from "@cospex/client/tracker/components/icons/Phone";
import HowTo from "@cospex/client/tracker/components/sections/HowTo";
import PhonesLocated from "@cospex/client/tracker/components/sections/PhonesLocated";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { t } = useTranslation();
  useTitle(t("home-page-title"));
  const { session } = useAuth();
  const navigate = useNavigate();

  const onTrack = () => {
    if (session?.email) {
      navigate("/dashboard");
      return;
    }
    navigate("/payment");
  };

  return (
    <Box>
      <Container maxWidth="xl">
        <Section>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h1" gutterBottom>
                  {t("home-title")}
                  <Box
                    component="span"
                    sx={{ display: "block", color: "primary.main" }}
                  >
                    {t("home-title-addendum")}
                  </Box>
                </Typography>
                <Typography variant="h5" sx={{ mb: 3 }}>
                  {t("home-description")}
                </Typography>
                <SearchPhone
                  onTrack={onTrack}
                  business="tracker"
                  modalAnimationData={modalAnimationData}
                />
                <FeaturesList
                  featureKeys={[t("home-features-1"), t("home-features-2")]}
                />
                <Counter>{t("home-numbers-identified")}</Counter>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginX: "auto",
                    aspectRatio: "812/864",
                    maxWidth: 400,
                  }}
                >
                  <HomepageGraphic />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Container>
      <Box>
        <Section>
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              rowGap: "2rem",
            }}
          >
            <Box flex="1 1 0">
              <Box
                component="img"
                height="auto"
                width="100%"
                maxWidth="500px"
                src={GlobeImage}
              />
            </Box>
            <Stack
              flex="1 1 0"
              sx={{ justifyContent: "center", rowGap: "1rem" }}
            >
              <HomeCard
                Icon={DeviceIcon}
                title={t("home-globe-phones-title")}
                desc={t("home-globe-phones-desc")}
                variant="h4"
              />
              <HomeCard
                Icon={Network}
                title={t("home-globe-operators-title")}
                desc={t("home-globe-operators-desc")}
                variant="h4"
              />
              <HomeCard
                Icon={Application}
                title={t("home-globe-applications-title")}
                desc={t("home-globe-applications-desc")}
                variant="h4"
              />
            </Stack>
          </Stack>
        </Section>
      </Box>
      <Container>
        <HowTo />
        <ServiceCompatible />
      </Container>
      <Box sx={{ backgroundColor: "primary.main", color: "white" }}>
        <Section>
          <Stack alignItems="center" mt="2rem">
            <Typography variant="h2" textAlign="center">
              {t("home-locate-title")}
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-evenly",
              columnGap: "2.5em",
              rowGap: "2.5em",
              paddingTop: "3rem",
            }}
          >
            <LocateCard
              Icon={Phone}
              title={t("home-locate-love-title")}
              desc={t("home-locate-love-desc")}
            />
            <LocateCard
              Icon={Phone}
              title={t("home-locate-stolen-title")}
              desc={t("home-locate-stolen-desc")}
            />
            <LocateCard
              Icon={Phone}
              title={t("home-locate-lost-title")}
              desc={t("home-locate-lost-desc")}
            />
          </Stack>
        </Section>
      </Box>
      <Container>
        <PhonesLocated />
      </Container>
      <Box sx={{ backgroundColor: "primary.main" }}>
        <Section>
          <Stack alignItems="center">
            <Pricing />
          </Stack>
        </Section>
      </Box>
    </Box>
  );
}
